<script setup>
import { showShareDialog, showCustomQRDialog } from "~/utils/dialogActions";
import { copyTextToClipboard } from "~/utils/copyTextToClipboard";

const props = defineProps({
  link: {
    type: String,
    required: true,
  },
  createdOn: {
    type: Date,
    required: true,
  },
  destinationUrl: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: false,
    default: "",
  },
});

const moment = (await import("moment-timezone")).default;

const buttons = [
  {
    id: "copyButton",
    label: "copy",
    icon: "pi pi-copy",
    action: () => copyTextToClipboard({ url: props.link }),
  },
  {
    id: "qrcodeButton",
    label: "qrcode",
    icon: "pi pi-qrcode",
    action: () => showCustomQRDialog({ link: props.link }),
  },
  {
    id: "shareButton",
    label: "share",
    icon: "pi pi-share-alt",
    action: () => showShareDialog({ link: props.link }),
  },
  {
    id: "analyticsButton",
    label: "analytics",
    icon: "pi pi-chart-line",
    action: () => visitAnalytics(),
  },
];

const visitAnalytics = async () => {
  const linkWithoutHttp = props.link.replaceAll("https://", "");
  const url = `https://smartlnks.com/stats/${linkWithoutHttp}`;
  await navigateTo(url, {
    external: true,
    open: {
      target: "_blank",
      windowFeatures: {
        width: 1024,
        height: 720,
      },
    },
  });
};
</script>
<template>
  <div>
    <div class="bg-white p-4 rounded-xl mb-3">
      <div class="flex items-center">
        <CustomSocialIcons
          :url="destinationUrl"
          :dynamicClass="'h-8 w-8 mr-3'"
        />
        <div>
          <span class="font-bold break-all text-base leading-3 block">{{ title || link }}</span>
          <span class="font-medium break-all text-sm leading-4" :class="{'text-xs' : !title}" >
            {{ title ? link  : destinationUrl}}
          </span>
          <!-- <span class="block text-xs break-all">{{ destinationUrl }}</span> -->
          <span class="text-xs block">{{
            moment(createdOn).format("MMM Do, h:mm a")
          }}</span>
        </div>
      </div>
      <div class="flex justify-between items-center mt-5">
        <Button
          v-for="button in buttons"
          :key="button.id"
          :id="button.id"
          :label="button.label"
          :icon="button.icon"
          class="history-op-link-btns p-button-sm flex items-center gap-1"
          :class="button.colorClass"
          @click="button.action"
          unstyled
        />
      </div>
    </div>
  </div>
</template>

<style lang="postcss">
/* #copyButton {
  @apply !bg-blue-500 !border-blue-500 hover:!bg-blue-600;
}

#qrcodeButton {
  @apply !bg-green-500 !border-green-500 hover:!bg-green-600;
}

#shareButton {
  @apply !bg-yellow-500 !border-yellow-500 hover:!bg-yellow-600;
}

#analyticsButton {
  @apply !bg-red-500 !border-red-500 hover:!bg-red-600;
} */
</style>

<style lang="postcss">
.history-op-link-btns {
  @apply !text-xs !py-1.5 !px-2;
}
</style>
